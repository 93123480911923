import React, { useState } from 'react'
import { Box, FormLabel, makeStyles, Button } from '@material-ui/core'
import FormInput from '../../../employee/auth/FormInput';

const CreateEmployee = () => {
  const classes = useStyles();
  const [disableButton, setDisable] = useState(false);
  const [state, setState] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
  })

  const handleOnTextChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value })
  }

  const buildAttributes = () => {
    return {
      ...state,
      profile_attributes: {
        first_name: state.first_name,
        middle_name: state.middle_name,
        last_name: state.last_name
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let method = 'POST'
    let url = '/leave_plans'
    setDisable(true)
    $.ajax({
      method: method,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ user: buildAttributes() }),
      dataType: 'json',
      success: (response, _x, _xhr) => {
        Cookies.set('user-type', 'admin-view', { secure: true })
        Cookies.set('leave-plan-id', response.data.leave_plan_id, { secure: true })
        Cookies.set('masqueraded-baseurl', response.redirect_to.split('/webapp')[1], { secure: true })
        Cookies.set('show-modal', true, { secure: true })

        toastr.success(response.message)
        window.location = response.redirect_to
      },
      error: (err) => {
        toastr.error(JSON.parse(err.responseText).message)
        setDisable(false)
      }
    })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{
        borderTopStyle: "solid", borderTopColor: "rgb(242, 242, 242)"
      }}>
      <h1 className={classes.title}>Who is this leaveplan created for?</h1>
      <Box display="flex" flexDirection="column" width="300px" marginTop={"10px"}>
        <FormLabel className={classes.label}>First Name</FormLabel>
        <FormInput
          id="user-first-name"
          variant="outlined"
          name="first_name"
          value={state.first_name}
          onChange={handleOnTextChange}
          margin="dense"
        />
      </Box>
      <Box display="flex" flexDirection="column" width="300px">
        <FormLabel className={classes.label}>Last Name</FormLabel>
        <FormInput
          id="user-last-name"
          variant="outlined"
          name="last_name"
          value={state.last_name}
          onChange={handleOnTextChange}
          margin="dense"
        />
      </Box>
      <Box display="flex" flexDirection="column" width="300px">
        <FormLabel className={classes.label}>Email</FormLabel>
        <FormInput
          id="user-email"
          variant="outlined"
          name="email"
          value={state.email}
          onChange={handleOnTextChange}
          margin="dense"
        />
      </Box>
      <Box display="flex" flexDirection="column" width="300px">
        <Button style={{ fontFamily: "MontserratRegular" }} className="primary-login-button" type="submit" onClick={handleSubmit} disabled={disableButton}>
          <span>Next</span>
        </Button>
      </Box>
    </Box >
  )
}

const useStyles = makeStyles({
  label: {
    fontSize: "15px",
    color: "#000",
    textAlign: "left",
    fontFamily: "MontserratRegular",
  },
  title: {
    fontFamily: "MontserratRegular",
  }
});


export default CreateEmployee;
