import React from 'react'
import Selectpicker from '../../shared/inputs/selectpicker'
import SwitchInput from '../../shared/inputs/SwitchInput'
import Autocomplete from '../../shared/inputs/autocomplete'
import Datepicker from '../../shared/inputs/datepicker'

class CustomFilterForm extends React.Component {
  constructor (props) {
    super(props)

    const { customFilter } = props

    this.state = {
      name: '',
      associatedTo: [],
      dataType: 'single_select',
      question: '',
      requiredForEmployee: false,
      multiselectOptions: [],
      selectedLocations: customFilter.selectedLocations,
      selectedReportsToLocations: customFilter.selectedReportsToLocations,
      checkboxForLocation: customFilter.selectedLocations && customFilter.selectedLocations.length > 0,
      checkboxForReportsToLocation: customFilter.selectedReportsToLocations && customFilter.selectedReportsToLocations.length > 0,
      selectedJobCategories: customFilter.selectedJobCategories || [],
      checkboxForJobCategory: customFilter.selectedJobCategories && customFilter.selectedJobCategories.length > 0,
      selectedCompanyLeaveScenarios: customFilter.selectedCompanyLeaveScenarios || [],
      checkboxForCompanyLeaveScenario: customFilter.selectedCompanyLeaveScenarios && customFilter.selectedCompanyLeaveScenarios.length > 0,
      customizedForEFile: customFilter.customizedForCompanyEligibilityFile,
      ...customFilter
    }
  }

  changedLocationHandler (checkbox, flag) {
    const checkboxMapper = { 'work_location':'checkboxForLocation', 'reports_to_location':'checkboxForReportsToLocation' }
    let checkboxType = checkboxMapper[checkbox]
    this.setState({[checkboxType]: flag})
  }

  changedJobHandler (checked) {
    this.setState({checkboxForJobCategory: checked})
  }

  jobCategoryChangedHandler (values) {
    this.setState({ selectedJobCategories: values })
  }

  changedCompanyLeaveScenarioHandler (checked) {
    this.setState({checkboxForCompanyLeaveScenario: checked})
  }

  companyLeaveScenarioChangedHandler (values) {
    this.setState({ selectedCompanyLeaveScenarios: values })
  }

  customizedForEFileChangeHandler(checked) {
    this.setState({customizedForEFile: checked})
  }

  stateChangedHandler (key, value) {
    this.setState({[key]: value})
  }

  changeRetiredAt(status) {
    if (status === 'retired' && !this.state.retired_at) {
      this.setState({retired_at: moment().format('YYYY-MM-DD')})
    }
  }

  optionChangedHandler (index, value) {
    let { multiselectOptions } = this.state
    multiselectOptions[index].name = value
    this.setState({multiselectOptions})
  }

  removeOptionHandler (index) {
    let { multiselectOptions } = this.state
    multiselectOptions.splice(index, 1)
    this.setState({multiselectOptions})
  }

  renderSelectOptions () {
    if (['single_select', 'multi_select'].includes(this.state.dataType)) {
      return (
        this.state.multiselectOptions.map((option, index) => {
          return (
            <div key={index}>
              <br/>
              <div className="row">
                <div className="col-md-6 col-md-offset-4 col-xs-8">
                  <input
                    type="text"
                    className="form-control"
                    value={option.name}
                    onChange={(e) => this.optionChangedHandler(index, e.target.value)}
                    required={true}
                  />
                </div>
                <div className="col-md-2 col-xs-4 remove-options">
                  <button className="fa fa-minus-circle pull-right" onClick={ () => this.removeOptionHandler(index) }></button>
                </div>
              </div>
            </div>
          )
        })
      )
    }
  }

  addOptionHandler () {
    let { multiselectOptions } = this.state

    multiselectOptions.push({})
    this.setState({multiselectOptions})
  }

  renderAddOptionButton () {
    if (['single_select', 'multi_select'].includes(this.state.dataType)) {
      return (
        <div className="col-xs-12 add-options">
          <button className="fa fa-plus-circle pull-right add-options" onClick={ this.addOptionHandler.bind(this) }></button>
        </div>
      )
    }
  }

  handleSubmit () {
    const leaveLocationIds = this.state.checkboxForLocation ? this.state.selectedLocations.map(location => location.id) : []
    const reportsToLocationIds = this.state.checkboxForReportsToLocation ? this.state.selectedReportsToLocations.map(location => location.id) : []
    const jobCategoryIds = this.state.checkboxForJobCategory ? this.state.selectedJobCategories : []
    const companyLeaveScenarioIds = this.state.checkboxForCompanyLeaveScenario ? this.state.selectedCompanyLeaveScenarios : []
    const params = {
      name: this.state.name,
      status: this.state.status,
      retired_at: this.state.retired_at,
      required_for_employee: this.state.requiredForEmployee,
      associated_to: this.state.associatedTo || [],
      leave_location_ids: leaveLocationIds,
      reports_to_location_ids: reportsToLocationIds,
      data_type: this.state.dataType,
      question: this.state.question,
      multi_select_options_attributes: this.state.multiselectOptions,
      job_category_ids: jobCategoryIds,
      company_leave_scenario_ids: companyLeaveScenarioIds,
      customized_for_company_eligibility_file: this.state.customizedForEFile
    }

    let method = 'POST'
    let url = '/admin/companies/custom_filters'
    if (this.props.isEdit) {
      method = 'PUT'
      url = `/admin/companies/custom_filters/${this.state.id}/`
    }

    $.ajax({
      method: method,
      url: url,
      data: params,
      success: (response) => {
        if (response.success) {
          this.props.onSubmit(response.data)
          toastr.success(response.message)
        } else {
          toastr.error(response.message)
        }
      }
    })
  }

  renderAutocompleteForWorkLocation () {
    return(
      <div className="row">
        <div className="col-md-12">
          <Autocomplete
            placeholder="Enter city or state to search for location"
            availableItems={this.props.availableLocations}
            selectedItems={this.props.isEdit ? this.state.selectedLocations : []}
            onChange={(selectedLocations) => this.setState({selectedLocations})}
          />
        </div>
      </div>
    )
  }

  renderAutocompleteForReportsToLocation () {
    return(
      <div className="row">
        <div className="col-md-12">
          <Autocomplete
            className='customized-reports-to-location'
            placeholder="Enter city or state to search for location"
            availableItems={this.props.availableLocations}
            selectedItems={this.props.isEdit ? this.state.selectedReportsToLocations : []}
            onChange={(selectedReportsToLocations) => this.setState({selectedReportsToLocations})}
          />
        </div>
      </div>
    )
  }

  renderJobCategoryDropdown() {
    return(
      <div className="row">
        <div className="col-md-8">
          <Selectpicker
            className="select-picker ccf-job-category form-control"
            name="job_category"
            optionsForSelect={this.props.companyJobCategories}
            multiSelect={true}
            defaultValue={this.props.isEdit ? this.state.selectedJobCategories : []}
            onChange={(value) => this.jobCategoryChangedHandler(value)}
          />
        </div>
      </div>
    )
  }

  renderCompanyLeaveScenarioDropdown() {
    return(
      <div className="row">
        <div className="col-md-8">
          <Selectpicker
            className="select-picker ccf-company-leave-scenario form-control"
            name="company_leave_scenario"
            optionsForSelect={this.props.companyLeaveScenarios}
            multiSelect={true}
            defaultValue={this.props.isEdit ? this.state.selectedCompanyLeaveScenarios: []}
            onChange={(values) => this.companyLeaveScenarioChangedHandler(values)}
          />
        </div>
      </div>
    )
  }

  renderQuestion(type) {
    return(
      <div>
        <div className="row ccf-question">
          <div className="col-md-4">
            <label> {type}</label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              name="question"
              className="form-control"
              value={this.state.question}
              onChange={(e) => this.stateChangedHandler('question', e.target.value)}
            />
          </div>
        </div>
        <br />
      </div>
    )
  }

  renderDataType() {
    return(
      <div className="row">
        <div className="col-md-4">
          <label> Question Type: </label>
        </div>
        <div className="col-md-8">
          <Selectpicker
            className="select-picker ccf-question-type form-control"
            name="data_type"
            optionsForSelect={[['SingleSelect', 'single_select'], ['MultiSelect', 'multi_select']]}
            defaultValue={this.state.dataType}
            onChange={(value) => this.stateChangedHandler('dataType', value)}
          />
        </div>
      </div>
    )
  }

  renderForm () {
    return(
      <div>
        <div className="row ccf-name">
          <div className="col-md-4">
            <label> Name: </label>
          </div>
          <div className="col-md-8">
            <input
              type="text"
              name="name"
              className="form-control"
              value={this.state.name}
              onChange={(e) => this.stateChangedHandler('name', e.target.value)}
            />
          </div>
        </div>
        <hr />

        { this.state.customizedForEFile && this.renderQuestion('Eligibility File Field') }
        { !this.state.customizedForEFile && this.renderQuestion('Question') }
        { this.renderDataType() }
        { this.renderSelectOptions() }
        { this.renderAddOptionButton() }
        <br />
        <div className="row ccf-required-for-employee">
          <div className="col-md-4">
            <label> Required for employee: </label>
          </div>
          <div className="col-md-8">
            <SwitchInput
              name='required_for_employee'
              className="required-for-employee"
              onChange={(checked) => this.stateChangedHandler('requiredForEmployee', checked)}
              checked={this.state.requiredForEmployee}
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-4">
            <label> Associated To: </label>
          </div>
          <div className="col-md-8">
            <Selectpicker
              className="associatable-to multi-select"
              name="associatable_to"
              optionsForSelect={[['Planning Guide', 'PlanningGuide'], ['Leave Benefit', 'LeaveBenefit'], ['Task Item', 'PolicyItem'], ['Manager Training Item', 'ManagerTrainingItem'], ['Company Leave Scenario', 'CompanyLeaveScenario']]}
              multiSelect={true}
              onChange={(value) => this.stateChangedHandler('associatedTo', value)}
              defaultValue={this.state.associatedTo}
            />
          </div>
        </div>
        <br/>
        <div className="row ccf-status">
          <div className="col-md-4">
            <label> Status </label>
          </div>
          <div className="col-md-8">
            <Selectpicker
              className="select-picker ccf-question-status form-control"
              name="status"
              optionsForSelect={[['Draft', 'draft'], ['Active', 'active'], ['Retired', 'retired']]}
              defaultValue={this.state.status}
              onChange={(value) => {
                this.stateChangedHandler('status', value)
                this.changeRetiredAt(value)
              }}
            />
          </div>
        </div>
        <br/>
        <div className="row ccf-retired-at">
          <div className="col-md-4">
            <label> Retired At </label>
          </div>
          <div className="col-md-8">
            <Datepicker
              className="ccf-question-retired-at form-control"
              name="retired_at"
              defaultValue={this.state.retired_at}
              onChange={(value) => this.stateChangedHandler('retired_at', value)}
            />
          </div>
        </div>
        <br />

        <div className="row ccf-customized-for-company-eligibility-file">
          <div className="col-md-4">
            <label> Customized for Company Eligibility File: </label>
          </div>
          <div className="col-md-8">
            <SwitchInput
              name='customized_for_company_eligibility_file'
              className="customized-for-company-eligibility-file"
              checked={this.state.customizedForEFile}
              onChange={(checked) => this.customizedForEFileChangeHandler(checked)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <label>Customized for Work Location: </label>
          </div>
          <div className="col-md-8">
            <SwitchInput
              id="switch_work_location"
              name="customized-work-location"
              checked={this.state.checkboxForLocation}
              onChange={(location) => this.changedLocationHandler('work_location', location)}
            />
          </div>
        </div>

        { this.state.checkboxForLocation && this.renderAutocompleteForWorkLocation() }

        <div className="row">
          <div className="col-md-4">
            <label>Customized for Reports To Location: </label>
          </div>
          <div className="col-md-8">
            <SwitchInput
              id="switch_reports_to_location"
              name="customized-reports-to-location"
              checked={ this.state.checkboxForReportsToLocation }
              onChange={(location) => this.changedLocationHandler('reports_to_location', location)}
            />
          </div>
        </div>

        { this.state.checkboxForReportsToLocation && this.renderAutocompleteForReportsToLocation() }

        <div className="row">
          <div className="col-md-4">
            <label>Customized for Job Category </label>
          </div>
          <div className="col-md-8">
            <SwitchInput
              id="switch_job_category"
              name="customized-job-category"
              checked={ this.state.checkboxForJobCategory }
              onChange={(checked) => this.changedJobHandler(checked)}
            />
          </div>
        </div>

        { this.state.checkboxForJobCategory && this.renderJobCategoryDropdown() }

        <div className="row">
          <div className="col-md-4">
            <label>Customized for Company Leave Scenarios</label>
          </div>
          <div className="col-md-8">
            <SwitchInput
              id="switch_company_leave_scenario"
              name="customized-company-leave-scenario"
              checked={ this.state.checkboxForCompanyLeaveScenario}
              onChange={(checked) => this.changedCompanyLeaveScenarioHandler(checked)}
            />
          </div>
        </div>

        { this.state.checkboxForCompanyLeaveScenario && this.renderCompanyLeaveScenarioDropdown() }

      </div>
    )
  }

  render () {
    return (
      <div className="react-modal modal-lg">
        <div className="back-shadow">
        </div>

        <div className="company-custom-filters">
          <div className="modal-content react-modal-content">
            <div className="modal-header">
              <button
                className="close error-modal-close"
                data-dismiss="modal"
                type="button"
                onClick={this.props.onModalClose}
              >
                ×
              </button>
              <h4 className="modal-title">
                { this.props.isEdit ? 'Edit Company Custom Filter' : 'Add Company Custom Filter' }
              </h4>
            </div>
            <div className="modal-body">
              { this.renderForm() }
            </div>
            <div className="modal-footer">
              <div className="buttons">
                <button
                  className='btn btn-success green pull-left'
                  onClick={ this.handleSubmit.bind(this) }
                >
                  Submit
                </button>
                <button className="btn btn-primary" onClick={this.props.onModalClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
export default CustomFilterForm;
